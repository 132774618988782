// ** Redux, Thunk & Root Reducer Imports
import thunk from "redux-thunk";
import createSagaMiddleware from "redux-saga";
import { all } from "redux-saga/effects";
import createDebounce from "redux-debounced";
import rootReducer from "../reducers/rootReducer";
import { createStore, applyMiddleware, compose } from "redux";
import { contestSagas } from "../../views/Contest/store/contestSaga";
import { artistOfTheWeekSagas } from "@src/views/ArtistOfTheWeek/store/contestSaga";
import { celebLiveSagas } from "../../views/CelebLive/store/celebLiveSaga";

function* rootSaga() {
  yield all([contestSagas(), artistOfTheWeekSagas(), celebLiveSagas()]);
}

// ** init middleware
const sagaMiddleware = createSagaMiddleware();
const middleware = [thunk, createDebounce(), sagaMiddleware];

// ** Dev Tools
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// ** Create store
const store = createStore(
  rootReducer,
  {},
  composeEnhancers(applyMiddleware(...middleware))
);

sagaMiddleware.run(rootSaga);

export { store };
