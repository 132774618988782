import { defaultContestFilters } from "../reducer";

export const GET_CONTEST_LIST = "GET_CONTEST_LIST";

export const SET_CONTEST_LIST = "SET_CONTEST_LIST";

export const TOGGLE_MODAL = "TOGGLE_MODAL";

export const GET_CURRENT_CONTEST = "GET_CURRENT_CONTEST";

export const SET_CONTEST_STATISTICS = "SET_CONTEST_STATISTICS";

export const SET_CONTEST_FILTERS = "SET_CONTEST_FILTERS";

export const SET_CONTEST_LIST_LOADING_STATE = "SET_CONTEST_LIST_LOADING_STATE";

export const TOGGLE_CONTEST_LIST_VIEW = "TOGGLE_CONTEST_LIST_VIEW";

export const GET_CONTEST_STATISTICS = "GET_CONTEST_STATISTICS";

export const RESET_CONTEST_FILTERS = "RESET_CONTEST_FILTERS";

export const GET_MORE_CONTEST = "GET_MORE_CONTEST";

export const SET_MORE_CONTEST = "SET_MORE_CONTEST";

export const setContestListLoadingState = (loading) => {
  return async (dispatch) => {
    await dispatch({
      type: SET_CONTEST_LIST_LOADING_STATE,
      loading,
    });
  };
};

export const getContestList = (query) => {
  return async (dispatch) => {
    await dispatch(setContestListLoadingState(true));
    await dispatch({
      type: GET_CONTEST_LIST,
      query,
    });
  };
};

export const getContestStatistics = (query) => {
  return async (dispatch) => {
    dispatch(setContestListLoadingState(true));
    dispatch({
      type: GET_CONTEST_STATISTICS,
      query,
    });
  };
};

export const fetchMoreContest = (query) => {
  // console.log("contestAction.query:", query)
  return async (dispatch) => {
    dispatch(setContestListLoadingState(true));
    dispatch({
      type: GET_MORE_CONTEST,
      query,
    });
  };
};

export const setContestFilters = ({ query, getMoreContest = false }) => {
  return async (dispatch) => {
    dispatch({
      type: SET_CONTEST_FILTERS,
      query,
    });

    if (getMoreContest) {
      await dispatch(fetchMoreContest(query));
    } else {
      await dispatch(getContestList(query));
    }

    await dispatch(getContestStatistics(query));
  };
};

export const resetContestFilters = () => {
  return async (dispatch) => {
    dispatch(setContestFilters({ query: defaultContestFilters }));
  };
};

export const toggleListView = (viewState) => {
  return (dispatch) => {
    dispatch({
      type: TOGGLE_CONTEST_LIST_VIEW,
      listView: viewState,
    });
  };
};
