export const clipsInitialFilters = {
  category_id: { value: "", label: "Video Category" },
  reported_category: { value: "", label: "Reported Category" },
  status: { value: "", label: "Video Status" },
  page: 1,
  limit: 50,
  search: "",
  fromdate: "",
  todate: "",
};

// ** Initial State
const initialState = {
  statData: {},
  allReportedContent: [],
  videoClipsQueryFilters: { ...clipsInitialFilters },
  redirect: "",
  data: [],
  totalPage: null,
  clipsLoadingState: false,
};

const reported = (state = initialState, action) => {
  switch (action.type) {
    case "GET_REPORTED_CONTENT":
      return {
        ...state,
        allReportedContent: action.data,
        totalPage: action.page,
      };
    case "SET_MORE_REPORTED_CONTENT":
      const { data } = action;

      const updatedList = [...state.allReportedContent, ...data];
      return {
        ...state,
        allReportedContent: updatedList,
        // totalPage: action.page,
      };
    case "GET_REPORTED_CONTENT_STATS":
      return { ...state, statData: action.data };
    case "SET_REPORTED_CONTENT": {
      return {
        ...state,
        videoClipsQueryFilters: {
          ...state.videoClipsQueryFilters,
          ...action.query,
        },
      };
    }
    case "SET_CLIPS_LOADING_STATE": {
      return {
        ...state,
        clipsLoadingState: action.loading,
      };
    }
    default:
      return { ...state };
  }
};
export default reported;
