// ** Initial State
const initialState = {
  statistics: {
    DAU: "0",
    MAU: "0",
    install: {
      totalInstall: "0",
      monthlyInstall: "0",
    },
    unInstall: {
      totalUninstall: "0",
      monthlyUninstall: "0",
    },
    churnTillDate: "0",
  },
  insallVsSignup: {
    install: "0",
    signUp: "0",
  },
  userAquisition: {
    advertisement: "0",
    referal: "0",
    organic: "0",
    newUserCount: "0",
    newUserPercentage: "0",
    newUserRevenue: "0",
  },
  usersgraph: [],
  userbydevice: [],
  categoryvsupload: [],
  categoryvsviews: [],
  averageviews: null,
  averagescreentime: null,
  averagevideotime: null,
  videolength: null,
  videoupload: 0,
  userbycity: [],
  statdata: [],
  statlistdata: [],
  freeCoinStatsData: [],
  gigsPayment: [],
  statlistdatapages: null,
  statsloader: false,
};

const dashboard = (state = initialState, action) => {
  switch (action.type) {
    case "GET_DAU":
      return { ...state, DAU: action.data };
    case "GET_MAU":
      return { ...state, MAU: action.data };
    case "SET_STATISTICS": {
      return { ...state, statistics: { ...state.statistics, ...action.data } };
    }

    case "SET_INSTALL_VS_SIGNUP": {
      return {
        ...state,
        insallVsSignup: { ...state.insallVsSignup, ...action.data },
      };
    }

    case "SET_USER_AQUISITION_DATA": {
      return {
        ...state,
        userAquisition: { ...state.userAquisition, ...action.data },
      };
    }

    case "GET_USER_BY_DEVICE":
      return { ...state, userbydevice: action.data };
    case "GET_USERS_GRAPH":
      return { ...state, usersgraph: action.data };
    case "GET_CATEGORY_VS_UPLOAD":
      return { ...state, categoryvsupload: action.data };
    case "GET_CATEGORY_VS_VIEWS":
      return { ...state, categoryvsviews: action.data };
    case "GET_OVERVIEW_STATS":
      return { ...state, overviewstats: action.data };
    case "GET_AVERAGE_SCREEN_TIME":
      return { ...state, averagescreentime: action.data };
    case "GET_AVERAGE_VIDEO_TIME":
      return { ...state, averagevideotime: action.data };
    case "GET_VIDEO_LENGTH":
      return { ...state, videolength: action.data };
    case "GET_VIDEO_UPLOAD":
      return { ...state, videoupload: action.data };
    case "GET_USER_BY_CITY":
      return { ...state, userbycity: action.data };
    case "GET_STAT_DETAILS":
      return { ...state, statdata: action.data };
    case "GET_STAT_LIST_DATA":
      return {
        ...state,
        statlistdata: action.data,
        statlistdatapages: action.pages,
      };
    case "DASHBOARD_STAT_LIST_LOADER":
      return { ...state, statsloader: action.loading };
    case "GET_FREE_COIN_STATS_DATA":
      return { ...state, freeCoinStatsData: action.data };
    case "GET_GIGS_PAYMENT":
      return { ...state, gigsPayment: action.data };
    default:
      return { ...state };
  }
};
export default dashboard;
