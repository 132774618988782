import {
  RESET_WOW_USER_LIST_FILTERS,
  SET_VOW_USERS_DATA,
  SET_WOW_USERS_LIST_LOADER_STATE,
  WOW_USERS_LIST_SET_QUERY,
  SET_VOW_STATS_DATA,
} from "../action/vowUsersActions";

// ** Initial State
export const initialWowUserListQuery = {
  page: 1,
  limit: 50,
  status: { value: "", label: "Select status" },
  gender: { value: "", label: "Select Gender" },
  userRole: { value: "", label: "Select User Role" },
  platform: {
    value: "", label: "Select Plateform"
  },
  search: "",
  filterby: "",
  fromdate: "",
  todate: "",
  sort: "",
  sortvalue: "",
  advanceSearch: { value: "", label: "Please Select" },
  videoCount: { value: "", label: "no. of Video Uploaded" },
  category_id:""
};

const initialState = {
  allData: [],
  data: [],
  usersListQuery: { ...initialWowUserListQuery },
  wowUsersStatistics: {
    totalUser: 0,
    androidUser: 0,
    iosUser: 0,
    inactive: 0,
    installed: 0,
  },
  userListLoader: true,
  total: 1,
  allfollowerlist: [],
  followerlist: [],
  totalfollower: 0,
  allfollowinglist: [],
  followinglist: [],
  totalfollowing: 0,
  selectedUser: {},
  notificationList: [],
  totalnotification: 0,
  Clips: [],
  pages: 0,
  totalClips: 0,
  downloaddata:[]
};

const wowusers = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_DATA":
      return { ...state, allData: action.data };
    case SET_VOW_USERS_DATA:
      // console.log("download", action.usersListDownload)
      return {
        
        ...state,
        wowUsersStatistics: {
          ...state.wowUsersStatistics,
          ...action.usersStatistics,
        },
        data: action.usersList,
        total: action.total
      };
      case SET_VOW_STATS_DATA:
        // console.log("download", action.usersListDownload)
        return {
          
          ...state,
          wowUsersStatistics: {
            ...state.wowUsersStatistics,
            ...action.usersStatistics,
          },
          // data: action.usersList,
          // total: action.total
        };
      // console.log("here-is-downloaddata", downloaddata)
    case "GET_USER":
      return { ...state, selectedUser: action.selectedUser };
    case WOW_USERS_LIST_SET_QUERY: {
      return {
        ...state,
        usersListQuery: {
          ...state.usersListQuery,
          ...action.query,
        }
      };
    }
    case "GET_WOWUSER_LIST_DOWNLOAD":
      return { ...state, downloaddata: action.data}
    case "GET_ALL_FOLLOWER_DATA":
      return { ...state, allfollowerlist: action.data };
    case "GET_FOLLOWER_LIST":
      return {
        ...state,
        followerlist: action.data,
        totalfollower: action.total,
      };
    case "GET_ALL_FOLLOWING_DATA":
      return { ...state, allfollowinglist: action.data };
    case "GET_FOLLOWING_LIST":
      return {
        ...state,
        followinglist: action.data,
        totalfollowing: action.total,
      };
    case "GET_NOTIFICATION_LIST":
      return {
        ...state,
        notificationList: action.data,
        totalnotification: action.total,
      };
    case "GET_CLIPS":
      return {
        ...state,
        Clips: [...action.data],
        totalClips: action.totalClips,
        pages: action.pages,
      };

    case SET_WOW_USERS_LIST_LOADER_STATE: {
      return {
        ...state,
        userListLoader: action.loading,
      };
    }

    case RESET_WOW_USER_LIST_FILTERS: {
      return {
        ...state,
        usersListQuery: initialWowUserListQuery,
      };
    }
    default:
      return { ...state };
  }
};
export default wowusers;
