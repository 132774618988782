export const initialListQuery = {
  page: 1,
  limit: 50,
  report_type: "",
  search: "",
  fromdate: "",
  todate: "",
  // from_user: "",
  // to_user: "",
  // sort: "transactionDate",
  // sortvalue: "DESC",
};

const initialState = {
  statdata: [],
  listdata: [],
  datapages: null,
  total_documents: null,
  data: [],
  userListLoader: true,
  usersListQuery: { ...initialListQuery },
  downloadData: [],
};

const reportedMessage = (state = initialState, action) => {
  switch (action.type) {
    case "GET_REPORTED_MESSAGE_STAT_DATA":
      return { ...state, statdata: action.data };
    case "GET_REPORTED_MESSAGE_LIST_DATA":
      return {
        ...state,
        listdata: action.data,
        total_documents: action.total_documents,
        datapages: action.page,
      };
    case "GET_REPORTED_MESSAGE_DOWNLOAD_DATA":
      return { ...state, downloadData: action.data };
    case "REPORTED_MESSAGE_LIST_LOADER": {
      return {
        ...state,
        userListLoader: action.loading,
      };
    }
    case "SET_REPORTED_MESSAGE_LIST_QUERY":
      return {
        ...state,
        usersListQuery: {
          ...state.usersListQuery,
          ...action.query,
        },
      };
    default:
      return { ...state };
  }
};
export default reportedMessage;
