export const initialListQuery = {
  page: 1,
  limit: 50,
  status: { value: "", label: "Select status" },
  search: "",
  fromdate: "",
  todate: "",
  from_user: "",
  to_user: "",
  sort: "transactionDate",
  sortvalue: "DESC",
};

const initialState = {
  statdata: [],
  listdata: [],
  datapages: null,
  total_documents: null,
  data: [],
  userListLoader: true,
  usersListQuery: { ...initialListQuery },
  downloadData: [],
};

const coinTransferHistory = (state = initialState, action) => {
  switch (action.type) {
    case "GET_COIN_TRANSFER_HISTORY_STAT_DATA":
      return { ...state, statdata: action.data };
    case "GET_COIN_TRANSFER_HISTORY_LIST_DATA":
      return {
        ...state,
        listdata: action.data,
        total_documents: action.total_documents,
      };
    case "COIN_TRANSFER_HISTORY_LIST_LOADER": {
      return {
        ...state,
        userListLoader: action.loading,
      };
    }
    case "GET_COIN_TRANSFER_HISTORY_DOWNLOAD_DATA": {
      return {
        ...state,
        downloadData: action.data,
      };
    }
    case "SET_COIN_TRANSFER_HISTORY_LIST_QUERY":
      return {
        ...state,
        usersListQuery: {
          ...state.usersListQuery,
          ...action.query,
        },
      };
    default:
      return { ...state };
  }
};
export default coinTransferHistory;
