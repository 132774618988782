import moment from "moment";

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = (obj) => Object.keys(obj).length === 0;
export const BASETITTLE = 'WT - '; 
// export const BASEURL = 'http://13.234.131.179:4001/api/admin'
const devConfig = "https://admindevapi.wowtalent.live/api/admin";
const prodConfig = "https://adminapi.wowtalent.live/api/admin";

export const BASEURL = process.env.REACT_APP_BASENAME === 'production' ? prodConfig : devConfig;

let token = "";

if (localStorage?.getItem("token")) {
  token = JSON.parse(localStorage.getItem("token"));
} else if (window && !window?.location?.pathname?.includes("/")) {
  localStorage.clear();
  window?.history?.go("/login");
}

export const Token = `wow-talent_6586563476534 ${token}`;
export const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
console.log("timezone", timezone)

// ** Returns K format from a number
export const kFormatter = (num) =>
  num > 999 ? `${(num / 1000).toFixed(1)}k` : num;

// ** Converts HTML to string
export const htmlToString = (html) => html.replace(/<\/?[^>]+(>|$)/g, "");

// ** Checks if the passed date is today
const isToday = (date) => {
  const today = new Date();
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  );
};

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (
  value,
  formatting = { month: "short", day: "numeric", year: "numeric" }
) => {
  if (!value) return value;
  return new Intl.DateTimeFormat("en-US", formatting).format(new Date(value));
};

export const formatDateMDY = (date) => {
  const formatedDate = moment(date).format("MMM Do, YYYY");
  return formatedDate;
};

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value);
  let formatting = { month: "short", day: "numeric" };

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: "numeric", minute: "numeric" };
  }

  return new Intl.DateTimeFormat("en-US", formatting).format(new Date(value));
};

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => localStorage.getItem("userData");
export const getUserData = () => JSON.parse(localStorage.getItem("userData"));

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = (userRole) => {
  // console.log(userRole)
  if (userRole === "admin") return "/";
  if (userRole === "staff") return "/";
  // if (userRole === 'client') return '/access-control'
  // return '/login'
};

// ** React Select Theme Colors
export const selectThemeColors = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: "#7367f01a", // for option hover bg-color
    primary: "#7367f0", // for selected option bg-color
    neutral10: "#7367f0", // for tags bg-color
    neutral20: "#ededed", // for input border-color
    neutral30: "#ededed", // for input hover border-color
  },
});

export const removeEmptyFieldsFromObject = (obj) => {
  const updatedObject = {};

  Object.keys(obj).forEach((key) => {
    if (obj[key] !== "") {
      updatedObject[key] = obj[key];
    }
  });

  return updatedObject;
};

export const convertToIndianNumber = (number) => {
  // const [integerPart, decimalPart] = String(number).split(".");

  // let formattedInteger = "";

  // // Insert comma after every two digits from the last digit
  // let count = 0;
  // for (let i = integerPart.length - 1; i >= 0; i--) {
  //   count++;
  //   formattedInteger = integerPart[i] + formattedInteger;

  //   if (count === 3 && i !== 0) {
  //     formattedInteger = `, ${formattedInteger}`;
  //     count = 0;
  //   }
  // }

  // const formattedNumber = decimalPart
  //   ? `${formattedInteger}.${decimalPart}`
  //   : formattedInteger;
  return number?.toLocaleString('en-IN');
};