import {
  SET_ARTIST_OF_THE_WEEK_FILTERS,
  SET_ARTIST_OF_THE_WEEK_LIST,
  SET_ARTIST_OF_THE_WEEK_LIST_LOADING_STATE,
  SET_ARTIST_OF_THE_WEEK_STATISTICS,
  SET_MORE_ARTIST_OF_THE_WEEK,
  TOGGLE_MODAL,
  GET_CURRENT_ARTIST_OF_THE_WEEK,
} from "../action/contestActions";

export const defaultArtistOfTheWeekFilters = {
  // category_id: { value: "", label: "Select Category" },
  // status: { value: "", label: "Select Status" },
  page: 1,
  limit: 10,
  search: "",
  fromdate: "",
  todate: "",
  sort:"startDate",
  sortvalue:"DESC"
};

// ** Initial State
const initialState = {
  // pages: 0,
  totalartistOfTheWeek: 0,
  listView: false,
  listLoadingState: false,
  artistOfTheWeekStatistics: [],

  artistOfTheWeekList: [],
  artistOfTheWeekFilters: defaultArtistOfTheWeekFilters,
  isModelOpen: false,
  artistOfTheWeekid: null,
  artistOfTheWeekdata: {},
};

const artistOfTheWeekReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ARTIST_OF_THE_WEEK_LIST: {
      // console.log("SET_ARTIST_OF_THE_WEEK_LIST: working")
      return {
        ...state,
        artistOfTheWeekList: action.artistOfTheWeekList,
        listLoadingState: false,
      };
    }

    case SET_ARTIST_OF_THE_WEEK_STATISTICS: {
      // console.log("SET_ARTIST_OF_THE_WEEK_STATISTICS: working");
      return {
        ...state,
        artistOfTheWeekStatistics: action.artistOfTheWeekStatistics,
        listLoadingState: false,
      };
    }

    case SET_MORE_ARTIST_OF_THE_WEEK: {
      // console.log("SET_MORE_ARTIST_OF_THE_WEEK: working")
      const { artistOfTheWeekList } = action;
      // const totalClips = action.totalClips;
      // const pages = action.pages;
      const updatedList = [
        ...state.artistOfTheWeekList,
        ...artistOfTheWeekList,
      ];

      // return { ...state, allClips: updatedClips, artistOfTheWeekList, pages };
      return { ...state, artistOfTheWeekList: updatedList };
    }

    case SET_ARTIST_OF_THE_WEEK_FILTERS: {
      return {
        ...state,
        artistOfTheWeekFilters: action.query,
      };
    }

    case SET_ARTIST_OF_THE_WEEK_LIST_LOADING_STATE: {
      // console.log("SET_ARTIST_OF_THE_WEEK_LIST_LOADING_STATE: working");
      return {
        ...state,
        listLoadingState: action.loading,
      };
    }
    case TOGGLE_MODAL:
      return {
        ...state,
        isModelOpen: action.data,
        artistOfTheWeekid: action.id,
      };
    case GET_CURRENT_ARTIST_OF_THE_WEEK:
      return { ...state, artistOfTheWeekdata: action.data };
    default:
      return { ...state };
  }
};

export default artistOfTheWeekReducer;
