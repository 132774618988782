export const initialListQuery = {
  page: 1,
  limit: 50,
  status: { value: "", label: "Select status" },
  search: "",
  fromdate: "",
  todate: "",
  sort: "DESC",
  sortcol: "transactionDate",
  action: { value: "", label: "Select action" },
};

const initialState = {
  statdata: [],
  listdata: [],
  datapages: null,
  total_documents: null,
  userListLoader: true,
  usersListQuery: { ...initialListQuery },
  downloadList: [],
};

const freeCoinEarning = (state = initialState, action) => {
  switch (action.type) {
    case "GET_FREE_COIN_EARNING_STAT_DATA":
      return { ...state, statdata: action.data };
    case "GET_FREE_COIN_EARNING_LIST_DATA":
      // console.log("redux", action.data);
      // console.log(action.total_documents);
      return {
        ...state,
        listdata: action.data,
        total_documents: action.total_documents,
      };
    case "FREE_COIN_EARNING_LIST_LOADER": {
      return {
        ...state,
        userListLoader: action.loading,
      };
    }
    case "GET_FREE_COIN_EARNING_DOWNLOAD_DATA":
      return {
        ...state,
        downloadList: action.data,
      };
    case "SET_FREE_COIN_EARNING_LIST_QUERY": {
      return {
        ...state,
        usersListQuery: {
          ...state.usersListQuery,
          ...action.query,
        },
      };
    }
    default:
      return { ...state };
  }
};
export default freeCoinEarning;
