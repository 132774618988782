export const initialListQuery = {
  page: 1,
  limit: 10,
  status: { value: "", label: "Select KYC status" },
  search: "",
  fromdate: "",
  todate: "",
  sort: "created_At",
  sortvalue: "DESC",
};

const initialState = {
  statdata: [],
  listdata: [],
  datapages: null,
  total_documents: null,
  userListLoader: true,
  usersListQuery: { ...initialListQuery },
  downloadData: [],
  approveKyc: [],
  rejectKyc: [],
};

const kyc = (state = initialState, action) => {
  switch (action.type) {
    case "GET_KYC_STAT_DATA":
      return { ...state, statdata: action.data };
    case "GET_KYC_LIST_DATA":
      return {
        ...state,
        listdata: action.data,
        total_documents: action.total_documents,
      };
    case "GET_KYC_DOWNLOAD_DATA":
      return {
        ...state,
        downloadData: action.data,
      };
    case "KYC_LIST_LOADER": {
      return {
        ...state,
        userListLoader: action.loading,
      };
    }
    case "SET_KYC_LIST_QUERY": {
      return {
        ...state,
        usersListQuery: {
          ...state.usersListQuery,
          ...action.query,
        },
      };
    }
    case "APPROVE_KYC_STATUS": {
      return {
        ...state,
        approveKyc: {
          ...state.data,
        },
      };
    }
    case "REJECT_KYC_STATUS": {
      return {
        ...state,
        rejectKyc: {
          ...state.data,
        },
      };
    }
    default:
      return { ...state };
  }
};
export default kyc;
