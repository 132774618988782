import {
  SET_CELEBLIVE_FILTERS,
  SET_CELEBLIVE_LIST,
  SET_CELEBLIVE_LIST_LOADING_STATE,
  SET_CELEBLIVE_STATISTICS,
  SET_MORE_CELEBLIVE,
  TIME_TOGGLE_MODAL,
  INTERVIEW_TOGGLE_MODAL,
  PERFORMANCE_TOGGLE_MODAL,
} from "../action/celebLiveActions";

export const defaultCelebLiveFilters = {
  page: 1,
  limit: 10,
  search: "",
  fromdate: "",
  todate: "",
};

// ** Initial State
const initialState = {
  pages: 0,
  totalContest: 0,
  listView: false,
  listLoadingState: false,
  celebLiveStatistics: {
  },
  slotList: [],
  contestFilters: defaultCelebLiveFilters,
  isModelOpen: false,
  isTimeModelOpen: false,
  isPerformanceModelOpen: false,
  isInterviewModelOpen: false,
  contestid: null,
  performanceData: null,
  interviewData: null,
  totalLength: null,
};

const celebLiveReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CELEBLIVE_LIST: {
      return {
        ...state,
        slotList: action.slotList,
        totalLength: action.totalDocuments,
        pages: action.pages,
        listLoadingState: false,
      };
    }
    case SET_CELEBLIVE_STATISTICS: {
      return {
        ...state,
        celebLiveStatistics: action.contestStatistics,
        listLoadingState: false,
      };
    }

    case SET_MORE_CELEBLIVE: {
      const { contestList } = action;
      // const totalClips = action.totalClips;
      const pages = action.pages;
      const updatedList = [...state.slotList, ...contestList];

      // return { ...state, allClips: updatedClips, contestList, pages };
      return { ...state, slotList: updatedList };
    }

    case SET_CELEBLIVE_FILTERS: {
      return {
        ...state,
        contestFilters: action.query,
      };
    }

    case SET_CELEBLIVE_LIST_LOADING_STATE: {
      return {
        ...state,
        listLoadingState: action.loading,
      };
    }
    case TIME_TOGGLE_MODAL:
      return {
        ...state,
        isTimeModelOpen: action.data,
        contestid: action.id,
      };
    case PERFORMANCE_TOGGLE_MODAL:
      return {
        ...state,
        isPerformanceModelOpen: action.data,
        performanceData: action.id,
      };
    case INTERVIEW_TOGGLE_MODAL:
      return {
        ...state,
        isInterviewModelOpen: action.data,
        interviewData: action.id,
      };

    default:
      return { ...state };
  }
};

export default celebLiveReducer;
