import { defaultCelebLiveFilters } from "../reducer";

export const GET_CELEBLIVE_LIST = "GET_CELEBLIVE_LIST";

export const SET_CELEBLIVE_LIST = "SET_CELEBLIVE_LIST";

export const TIME_TOGGLE_MODAL = "TIME_TOGGLE_MODAL";

export const PERFORMANCE_TOGGLE_MODAL = "PERFORMANCE_TOGGLE_MODAL";

export const INTERVIEW_TOGGLE_MODAL = "INTERVIEW_TOGGLE_MODAL";

export const SET_CELEBLIVE_STATISTICS = "SET_CELEBLIVE_STATISTICS";

export const SET_CELEBLIVE_FILTERS = "SET_CELEBLIVE_FILTERS";

export const SET_CELEBLIVE_LIST_LOADING_STATE =
  "SET_CELEBLIVE_LIST_LOADING_STATE";

export const GET_CELEBLIVE_STATISTICS = "GET_CELEBLIVE_STATISTICS";

export const RESET_CELEBLIVE_FILTERS = "RESET_CELEBLIVE_FILTERS";

export const GET_MORE_CELEBLIVE = "GET_MORE_CELEBLIVE";

export const SET_MORE_CELEBLIVE = "SET_MORE_CELEBLIVE";

export const setCelebLiveListLoadingState = (loading) => {
  return async (dispatch) => {
    await dispatch({
      type: SET_CELEBLIVE_LIST_LOADING_STATE,
      loading,
    });
  };
};

export const getCelebLiveList = (query) => {
  return async (dispatch) => {
    await dispatch(setCelebLiveListLoadingState(true));
    await dispatch({
      type: GET_CELEBLIVE_LIST,
      query,
    });
  };
};

export const getCelebLiveStatistics = (query) => {
  return async (dispatch) => {
    dispatch(setCelebLiveListLoadingState(true));
    dispatch({
      type: GET_CELEBLIVE_STATISTICS,
      query,
    });
  };
};

export const fetchMoreCelebLive = (query) => {
  return async (dispatch) => {
    dispatch(setCelebLiveListLoadingState(true));
    dispatch({
      type: GET_MORE_CELEBLIVE,
      query,
    });
  };
};

export const setCelebLiveFilters = ({ query, getMoreContest = false }) => {
  return async (dispatch) => {
    dispatch({
      type: SET_CELEBLIVE_FILTERS,
      query,
    });

    if (getMoreContest) {
      await dispatch(fetchMoreCelebLive(query));
    } else {
      await dispatch(getCelebLiveList(query));
    }

    await dispatch(getCelebLiveStatistics(query));
  };
};

export const resetContestFilters = () => {
  return async (dispatch) => {
    dispatch(setCelebLiveFilters({ query: defaultCelebLiveFilters }));
  };
};
