import {
  RESET_CLIPS_FILTERS,
  SET_CLIPS_FILTERS,
  SET_CLIPS_LOADING_STATE,
  SET_CLIPS_STATISTICS,
  SET_MORE_CLIPS,
  TOGGLE_LIST_VIEW,
} from "../action/clipsActions";

export const clipsInitialFilters = {
  category_id: { value: "", label: "Select Category" },
  status: { value: "", label: "Select Status" },
  page: 1,
  limit: 50,
  search: "",
  fromdate: "",
  todate: "",
  user_id: "",
  rating_from: { value: "", label: "Expert rating From" },
  rating_to: { value: "", label: "Expert rating To" },
};
export const modalInitiallyQuery = {
  page: 1,
  limit: 10,
};
// ** Initial State
const initialState = {
  allClips: [],
  pages: 0,
  totalClips: 0,
  videoClipsQueryFilters: { ...clipsInitialFilters },
  clipsLoadingState: true,
  videoClipsStatistics: {
    totalVideo: 0,
    publishedVideo: 0,
    privateVideo: 0,
    bannedVideo: 0,
  },
  listView: false,
  allClipsClone: [],

  videoViewUserList: [],
  videoViewUserListTotalDocuments: 0,
  videoViewUserListPage: 0,

  videoLikeUserList: [],
  videoLikeUserListTotalDocuments: 0,
  videoLikeUserListPage: 0,

  videoCommentUserList: [],
  videoCommentUserListTotalDocuments: 0,
  videoCommentUserListPage: 0,

  videoShareUserList: [],
  videoShareUserListTotalDocuments: 0,
  videoShareUserListPage: 0,

  videoCoinUserList: [],
  videoCoinUserListTotalDocuments: 0,
  videoCoinUserListPage: 0,

  videoRatingUserList: [],
  videoRatingUserListTotalDocuments: 0,
  videoRatingUserListPage: 0,

  videoDownloadUserList: [],
  videoDownloadUserListTotalDocuments: 0,
  videoDownloadUserListPage: 0,
  modalQuery: { ...modalInitiallyQuery },

  downloadXlsx: { ...clipsInitialFilters, limit: 500 },
};

let previousSelectedCategory;

const clips = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_CLIPS": {
      const pages = action.pages;
      const totalClips = action.totalClips;
      return {
        ...state,
        allClips: action.data,
        allClipsClone: action.data,
        totalClips,
        pages,
      };
    }

    case SET_MORE_CLIPS: {
      const { data } = action;
      const totalClips = action.totalClips;
      const pages = action.pages;
      const updatedClips = [...state.allClips, ...data];
      return { ...state, allClips: updatedClips, totalClips, pages };
    }

    case "REMOVE_CLIP": {
      const { videoId } = action;
      const clipsList = [...state.allClips];
      const updatedClips = clipsList.filter((clip) => clip._id !== videoId);

      return {
        ...state,
        allClips: updatedClips,
        totalClips: action.totalClips - 1,
      };
    }

    case "RESET_CLIPS_LIST": {
      return {
        ...state,
        allClips: [],
      };
    }

    case SET_CLIPS_FILTERS: {
      return {
        ...state,
        videoClipsQueryFilters: action.query,
      };
    }

    case RESET_CLIPS_FILTERS: {
      return {
        ...state,
        videoClipsQueryFilters: clipsInitialFilters,
      };
    }

    case SET_CLIPS_STATISTICS: {
      return {
        ...state,
        videoClipsStatistics: action.clipstatistics,
      };
    }

    case TOGGLE_LIST_VIEW: {
      return {
        ...state,
        listView: action.listView,
        allClips: state.allClipsClone,
      };
    }

    case SET_CLIPS_LOADING_STATE: {
      return {
        ...state,
        clipsLoadingState: action.loading,
      };
    }
    case "GET_VIDEO_VIEW_USER_LIST_IN_VIDEO_CLIPS": {
      // console.log("redux", action.data);
      return {
        ...state,
        videoViewUserList: action.data,
        videoViewUserListTotalDocuments: action.total_documents,
        videoViewUserListPage: action.viewListPage,
      };
    }
    case "SET_MORE_VIEW_USER_LIST_IN_VIDEO": {
      const updatedVideoViewUserList = [
        ...state.videoViewUserList,
        ...action.data,
      ];
      return {
        ...state,
        videoViewUserList: updatedVideoViewUserList,
        videoViewUserListTotalDocuments: action.total_documents,
        videoViewUserListPage: action.viewListPage,
      };
    }
    case "SET_VIEW_LIST_QUERY": {
      return {
        ...state,
        modalQuery: {
          ...state.modalQuery,
          ...action.query,
        },
      };
    }
    case "GET_VIDEO_LIKE_USER_LIST_IN_VIDEO_CLIPS": {
      // console.log("redux", action.data);
      return {
        ...state,
        videoLikeUserList: action.data,
        videoLikeUserListTotalDocuments: action.total_documents,
        videoLikeUserListPage: action.likeListPage,
      };
    }
    case "SET_MORE_LIKE_USER_LIST_IN_VIDEO": {
      const updatedVideoLikeUserList = [
        ...state.videoLikeUserList,
        ...action.data,
      ];
      return {
        ...state,
        videoLikeUserList: updatedVideoLikeUserList,
        videoLikeUserListTotalDocuments: action.total_documents,
        videoLikeUserListPage: action.likeListPage,
      };
    }
    case "SET_LIKE_LIST_QUERY": {
      return {
        ...state,
        modalQuery: {
          ...state.modalQuery,
          ...action.query,
        },
      };
    }
    case "GET_VIDEO_COMMENT_USER_LIST_IN_VIDEO_CLIPS": {
      // console.log("redux", action.data);
      return {
        ...state,
        videoCommentUserList: action.data,
        videoCommentUserListTotalDocuments: action.total_documents,
        videoCommentUserListPage: action.commentListPage,
      };
    }
    case "SET_MORE_COMMENT_USER_LIST_IN_VIDEO": {
      const updatedVideoCommentUserList = [
        ...state.videoCommentUserList,
        ...action.data,
      ];
      return {
        ...state,
        videoCommentUserList: updatedVideoCommentUserList,
        videoCommentUserListTotalDocuments: action.total_documents,
        videoCommentUserListPage: action.commentListPage,
      };
    }
    case "SET_COMMENT_LIST_QUERY": {
      return {
        ...state,
        modalQuery: {
          ...state.modalQuery,
          ...action.query,
        },
      };
    }
    case "GET_VIDEO_SHARE_USER_LIST_IN_VIDEO_CLIPS": {
      // console.log("redux", action.data);
      return {
        ...state,
        videoShareUserList: action.data,
        videoShareUserListTotalDocuments: action.total_documents,
        videoShareUserListPage: action.shareListPage,
      };
    }
    case "SET_MORE_SHARE_USER_LIST_IN_VIDEO": {
      // const { data } = action;
      // const totalClips = action.totalClips;
      // const pages = action.pages;
      // const updatedClips = [...state.allClips, ...data];
      const updatedVideoShareUserList = [
        ...state.videoShareUserList,
        ...action.data,
      ];
      return {
        ...state,
        videoShareUserList: updatedVideoShareUserList,
        videoShareUserListTotalDocuments: action.total_documents,
        videoShareUserListPage: action.shareListPage,
      };
    }
    case "SET_SHARE_LIST_QUERY": {
      return {
        ...state,
        modalQuery: {
          ...state.modalQuery,
          ...action.query,
        },
      };
    }
    case "GET_VIDEO_COIN_USER_LIST_IN_VIDEO_CLIPS": {
      // console.log("redux", action.data);
      return {
        ...state,
        videoCoinUserList: action.data,
        videoCoinUserListTotalDocuments: action.total_documents,
        videoCoinUserListPage: action.coinListPage,
      };
    }
    case "SET_MORE_COIN_USER_LIST_IN_VIDEO": {
      const updatedVideoCoinUserList = [
        ...state.videoCoinUserList,
        ...action.data,
      ];
      return {
        ...state,
        videoCoinUserList: updatedVideoCoinUserList,
        videoCoinUserListTotalDocuments: action.total_documents,
        videoCoinUserListPage: action.coinListPage,
      };
    }
    case "SET_COIN_LIST_QUERY": {
      return {
        ...state,
        modalQuery: {
          ...state.modalQuery,
          ...action.query,
        },
      };
    }
    case "GET_VIDEO_RATING_USER_LIST_IN_VIDEO_CLIPS": {
      // console.log("redux", action.data)
      // const updatedVideoRatingUserList = [...state.videoRatingUserList, ...action.data];
      return {
        ...state,
        videoRatingUserList: action.data,
        videoRatingUserListTotalDocuments: action.total_documents,
        videoRatingUserListPage: action.ratingListPage,
      };
    }
    case "SET_MORE_RATING_USER_LIST_IN_VIDEO": {
      // const { data } = action;
      // const totalClips = action.totalClips;
      // const pages = action.pages;
      // const updatedClips = [...state.allClips, ...data];
      const updatedVideoRatingUserList = [
        ...state.videoRatingUserList,
        ...action.data,
      ];
      return {
        ...state,
        videoRatingUserList: updatedVideoRatingUserList,
        videoRatingUserListTotalDocuments: action.total_documents,
        videoRatingUserListPage: action.ratingListPage,
      };
    }
    case "SET_RATING_LIST_QUERY": {
      return {
        ...state,
        modalQuery: {
          ...state.modalQuery,
          ...action.query,
        },
      };
    }
    case "GET_VIDEO_DOWNLOAD_USER_LIST_IN_VIDEO_CLIPS": {
      // console.log("redux", action.data);
      return {
        ...state,
        videoDownloadUserList: action.data,
        videoDownloadUserListTotalDocuments: action.total_documents,
        videoDownloadUserListPage: action.downloadListPage,
      };
    }
    case "SET_MORE_DOWNLOAD_USER_LIST_IN_VIDEO": {
      const updatedVideoDownloadUserList = [
        ...state.videoDownloadUserList,
        ...action.data,
      ];
      return {
        ...state,
        videoDownloadUserList: updatedVideoDownloadUserList,
        videoDownloadUserListTotalDocuments: action.total_documents,
        videoDownloadUserListPage: action.DownloadListPage,
      };
    }
    case "SET_DOWNLOAD_LIST_QUERY": {
      return {
        ...state,
        modalQuery: {
          ...state.modalQuery,
          ...action.query,
        },
      };
    }
    case "GET_DOWNLOAD_CLIPS": {
      return {
        ...state,
        downloadXlsx: action.data
      };
    }
    default:
      return { ...state };
  }
};
export default clips;
