import axios from "axios";
import { toast } from "react-toastify";
import { all, takeLatest, put, call } from "redux-saga/effects";
import {
  GET_ARTIST_OF_THE_WEEK_LIST,
  GET_ARTIST_OF_THE_WEEK_STATISTICS,
  GET_MORE_ARTIST_OF_THE_WEEK,
  SET_ARTIST_OF_THE_WEEK_LIST,
  SET_ARTIST_OF_THE_WEEK_STATISTICS,
  SET_MORE_ARTIST_OF_THE_WEEK,
} from "./action/contestActions";
import { BASEURL, Token, timezone } from "@utils";
import { removeEmptyFieldsFromObject } from "../../../utility/Utils";
import { defaultArtistOfTheWeekFilters } from "./reducer";

const getArtistOfTheWeekList = async (query) => {
  try {
    const simplifiedQuery = {
      ...query,
      //   category_id: query.category_id.value,
      //   status: query.status.value,
    };

    // simplifiedQuery.page = defaultArtistOfTheWeekFilters.page;
    const updatedQuery = removeEmptyFieldsFromObject(simplifiedQuery);

    const artistOfTheWeekResponse = await axios.get(
      `${BASEURL}/user/artistoftheweek`,
      {
        params: {
          ...updatedQuery,
        },
        headers: { Token, timezone },
      }
    );

    if (!artistOfTheWeekResponse?.data?.data?.data) {
      if (artistOfTheWeekResponse?.data?.error) {
        toast.error(
          artistOfTheWeekResponse?.data?.error.msg ??
            "Something went wrong while getting artistOfTheWeek list!"
        );
        console.log(
          "Something went wrong while getting artistOfTheWeek list!",
          artistOfTheWeekResponse?.data?.error
        );
        return;
      }
      console.log(
        "Something went wrong while getting artistOfTheWeek list!",
        artistOfTheWeekResponse?.data?.error
      );
      toast.error("Something went wrong while getting artistOfTheWeek list!");
      return [];
    }

    return artistOfTheWeekResponse?.data?.data;
  } catch (err) {
    console.log(
      "Something went wrong while getting artistOfTheWeek list!",
      err
    );
    toast.error("Something went wrong while getting artistOfTheWeek list!");
  }
};

export const fetchMoreArtistOfTheWeek = async (query) => {
  // console.log("fetchMoreArtistOfTheWeek: working");
  try {
    const simplifiedQuery = {
      ...query,
      // category_id: query.category_id.value,
      // status: query.status.value,
    };
    const updatedQuery = removeEmptyFieldsFromObject(simplifiedQuery);
    const artistOfTheWeekResponse = await axios.get(
      `${BASEURL}/user/artistoftheweek`,
      {
        params: {
          ...updatedQuery,
        },
        headers: { Token, timezone },
      }
    );

    if (!artistOfTheWeekResponse?.data?.data?.data) {
      if (artistOfTheWeekResponse?.data?.error) {
        toast.error(
          artistOfTheWeekResponse?.data?.error.msg ??
            "Something went wrong while getting artist Of The Week list!"
        );
        console.log(
          "Something went wrong while getting artist Of The Week list!",
          artistOfTheWeekResponse?.data?.error
        );
        return;
      }
      console.log(
        "Something went wrong while getting artist Of The Week list!",
        artistOfTheWeekResponse?.data?.error
      );
      toast.error(
        "Something went wrong while getting artist Of The Week list!"
      );
      return [];
    }

    return artistOfTheWeekResponse?.data?.data;
  } catch (err) {
    console.log(
      "Something went wrong while getting artist Of The Week list!",
      err
    );
    toast.error("Something went wrong while getting artist Of The Week list!");
  }
};

const getArtistOfTheWeekStatistics = async (query) => {
  //   console.log("getArtistOfTheWeekStatistic: working in saga");

  try {
    const simplifiedQuery = {
      ...query,
      // category_id: query.category_id.value,
      // status: query.status.value,
      search: query?.search,
    };

    delete simplifiedQuery.page;
    // delete simplifiedQuery.search;
    delete simplifiedQuery.limit;
    delete simplifiedQuery.sort;
    delete simplifiedQuery.sortvalue;

    const updatedQuery = removeEmptyFieldsFromObject(simplifiedQuery);

    const artistOfTheWeekStatisticsResponse = await axios.get(
      `${BASEURL}/user/artistoftheweekcount`,
      {
        params: {
          ...updatedQuery,
        },
        headers: { Token, timezone },
      }
    );
    if (!artistOfTheWeekStatisticsResponse?.data?.data) {
      if (artistOfTheWeekStatisticsResponse?.data?.error) {
        toast.error(
          artistOfTheWeekStatisticsResponse?.data?.error.msg ??
            "Something went wrong while getting artistOfTheWeek statistics!"
        );
        console.log(
          "Something went wrong while getting artistOfTheWeek statistics!",
          err
        );
        return;
      }
      console.log(
        "Something went wrong while getting artistOfTheWeek list!",
        err
      );
      toast.error("Something went wrong while getting artistOfTheWeek list!");
      return undefined;
    }

    return artistOfTheWeekStatisticsResponse?.data?.data;
  } catch (err) {
    console.log(
      "Something went wrong while getting artistOfTheWeek statistics!",
      err
    );
    toast.error(
      "Something went wrong while getting artistOfTheWeek statistics!"
    );
  }
};

function* getArtistOfTheWeeksSaga(action) {
  // console.log("getArtistOfTheWeeksSaga: working");
  try {
    const artistOfTheWeekList = yield call(
      getArtistOfTheWeekList,
      action.query
    );

    yield put({
      type: SET_ARTIST_OF_THE_WEEK_LIST,
      artistOfTheWeekList: artistOfTheWeekList,
    });
  } catch (err) {
    console.log(
      "Something went wrong while getting artistOfTheWeek list!",
      err
    );
    toast.error("Something went wrong while getting artistOfTheWeek list!");
  }
}

function* getArtistOfTheWeekStatisticsSaga(action) {
  //   console.log("getArtistOfTheWeekStatisticsSaga: working");

  try {
    const artistOfTheWeekStatistics = yield call(
      getArtistOfTheWeekStatistics,
      action.query
    );
    yield put({
      type: SET_ARTIST_OF_THE_WEEK_STATISTICS,
      artistOfTheWeekStatistics,
    });
  } catch (err) {
    console.log(
      "Something went wrong while getting artistOfTheWeek statistics!",
      err
    );
    toast.error(
      "Something went wrong while getting artistOfTheWeek statistics!"
    );
  }
}

function* getMoreArtistOfTheWeekSaga(action) {
  // console.log("getMoreArtistOfTheWeekSaga: working");
  try {
    const artistOfTheWeekList = yield call(
      fetchMoreArtistOfTheWeek,
      action.query
    );

    yield put({
      type: SET_MORE_ARTIST_OF_THE_WEEK,
      artistOfTheWeekList: artistOfTheWeekList,
    });
  } catch (err) {
    console.log(
      "Something went wrong while getting artistOfTheWeek list!",
      err
    );
    toast.error("Something went wrong while getting artistOfTheWeek list!");
  }
}

export function* artistOfTheWeekSagas() {
  // console.log("artistOfTheWeekSagas: working");
  yield all([
    takeLatest(GET_ARTIST_OF_THE_WEEK_LIST, getArtistOfTheWeeksSaga),
    takeLatest(
      GET_ARTIST_OF_THE_WEEK_STATISTICS,
      getArtistOfTheWeekStatisticsSaga
    ),
    takeLatest(GET_MORE_ARTIST_OF_THE_WEEK, getMoreArtistOfTheWeekSaga),
  ]);
}
