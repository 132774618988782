export const initialListQuery = {
  page: 1,
  limit: 50,
  status: { value: "", label: "Select status" },
  search: "",
  fromdate: "",
  todate: "",
  sort: "withdrawals_request_date",
  sortvalue: "DESC"
};

const initialState = {
  statdata: [],
  listdata: [],
  data: [],
  total_documents:null,
  userListLoader: true,
  usersListQuery: { ...initialListQuery },
  downloadData:[]
};

const coinWithdrawal = (state = initialState, action) => {
  switch (action.type) {
    case "GET_COIN_WITHDRAWAL_STAT_DATA":
      return { ...state, statdata: action.data };
    case "GET_COIN_WITHDRAWAL_LIST_DATA":
      return {
        ...state,
        listdata: action.data,
        total_documents: action.total_documents,
      };
      case "GET_COIN_WITHDRAWAL_DOWNLOAD_DATA":
        return {
          ...state,
          downloadData: action.data,
          // total_documents: action.total_documents,
        };
      case "COIN_WITHDRAWAL_LIST_LOADER": {
        return {
          ...state,
          userListLoader: action.loading,
        };
      }
    case "SET_COIN_WITHDRAWAL_LIST_QUERY":
      return {
        ...state,
        usersListQuery: {
          ...state.usersListQuery,
          ...action.query,
        },
      };
    default:
      return { ...state };
  }
};
export default coinWithdrawal;
