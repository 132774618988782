export const initialListQuery = {
  page: 1,
  limit: 10,
  status: { value: "", label: "Select status" },
  search: "",
  fromdate: "",
  todate: "",
  sort: "",
  sortvalue: "",
};

const initialState = {
  statdata: [],
  listdata: [],
  data: [],
  total_documents: null,
  userListLoader: true,
  usersListQuery: { ...initialListQuery },
  downloadData: [],
};

const coinPurchase = (state = initialState, action) => {
  switch (action.type) {
    case "GET_COIN_PURCHASED_STAT_DATA":
      return { ...state, statdata: action.data };
    case "GET_COIN_PURCHASED_LIST_DATA":
      return {
        ...state,
        listdata: action.data,
        total_documents: action.total_documents,
      };
    case "GET_COIN_PURCHASED_DOWNLOAD_DATA": {
      return {
        ...state,
        downloadData: action.data,
      };
    }

    case "COIN_PURCHASED_LIST_LOADER": {
      return {
        ...state,
        userListLoader: action.loading,
      };
    }
    case "SET_COIN_PURCHASED_LIST_QUERY": {
      // console.log("in redux", action.query)
      return {
        ...state,
        usersListQuery: {
          ...state.usersListQuery,
          ...action.query,
        },
      };
    }
    default:
      return { ...state };
  }
};
export default coinPurchase;
