import { defaultArtistOfTheWeekFilters } from "../reducer";

export const GET_ARTIST_OF_THE_WEEK_LIST = "GET_ARTIST_OF_THE_WEEK_LIST";

export const SET_ARTIST_OF_THE_WEEK_LIST = "SET_ARTIST_OF_THE_WEEK_LIST";

export const TOGGLE_MODAL = "TOGGLE_MODAL";

export const GET_CURRENT_ARTIST_OF_THE_WEEK = "GET_CURRENT_ARTIST_OF_THE_WEEK";

export const SET_ARTIST_OF_THE_WEEK_STATISTICS = "SET_ARTIST_OF_THE_WEEK_STATISTICS";

export const SET_ARTIST_OF_THE_WEEK_FILTERS = "SET_ARTIST_OF_THE_WEEK_FILTERS";

export const SET_ARTIST_OF_THE_WEEK_LIST_LOADING_STATE = "SET_ARTIST_OF_THE_WEEK_LIST_LOADING_STATE";

export const TOGGLE_ARTIST_OF_THE_WEEK_LIST_VIEW = "TOGGLE_ARTIST_OF_THE_WEEK_LIST_VIEW";

export const GET_ARTIST_OF_THE_WEEK_STATISTICS = "GET_ARTIST_OF_THE_WEEK_STATISTICS";

export const RESET_ARTIST_OF_THE_WEEK_FILTERS = "RESET_ARTIST_OF_THE_WEEK_FILTERS";

export const GET_MORE_ARTIST_OF_THE_WEEK = "GET_MORE_ARTIST_OF_THE_WEEK";

export const SET_MORE_ARTIST_OF_THE_WEEK = "SET_MORE_ARTIST_OF_THE_WEEK";

export const setArtistOfTheWeekListLoadingState = (loading) => {
  return async (dispatch) => {
    // console.log("setArtistOfTheWeekListLoadingState:working")
    dispatch({
      type: SET_ARTIST_OF_THE_WEEK_LIST_LOADING_STATE,
      loading,
    });
  };
};

export const getArtistOfTheWeekList = (query) => {
  // console.log("getArtistOfTheWeekList: working")
  return async (dispatch) => {
    dispatch(setArtistOfTheWeekListLoadingState(true));
    dispatch({
      type: GET_ARTIST_OF_THE_WEEK_LIST,
      query,
    });
  };
};

export const getArtistOfTheWeekStatistics = (query) => {
  return async (dispatch) => {
    // console.log("getArtistOfTheWeekStatistics: working")
    dispatch(setArtistOfTheWeekListLoadingState(true));
    dispatch({
      type: GET_ARTIST_OF_THE_WEEK_STATISTICS,
      query,
    });
  };
};

export const fetchMoreArtistOfTheWeek = ({...query}) => {
  // console.log("fetchMoreArtistOfTheWeek: working")
  return async (dispatch) => {
    dispatch(setArtistOfTheWeekListLoadingState(true));
    dispatch({
      type: GET_MORE_ARTIST_OF_THE_WEEK,
      query,
    });
  };
};

export const setArtistOfTheWeekFilters = ({ query, getMoreArtistOfTheWeek = false }) => {
  // console.log("setArtistOfTheWeekFilters query", query)
  return (dispatch) => {
    dispatch({
      type: SET_ARTIST_OF_THE_WEEK_FILTERS,
      query,
    });

    if (getMoreArtistOfTheWeek) {
      dispatch(fetchMoreArtistOfTheWeek(query));
    } else {
      dispatch(getArtistOfTheWeekList(query));
    }

    dispatch(getArtistOfTheWeekStatistics(query));
  };
};

export const resetArtistOfTheWeekFilters = () => {
  return async (dispatch) => {
    dispatch(setArtistOfTheWeekFilters({ query: defaultArtistOfTheWeekFilters }));
  };
};

export const toggleListView = (viewState) => {
  return (dispatch) => {
    dispatch({
      type: TOGGLE_ARTIST_OF_THE_WEEK_LIST_VIEW,
      listView: viewState,
    });
  };
};
