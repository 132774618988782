export const clipsInitialFilters = {
  moderation: { value: "", label: "Moderation %" },
  category_id: { value: "", label: "Video Category" },
  reported_category: { value: "", label: "Reported Category" },
  status: { value: "", label: "Video Status" },
  page: 1,
  limit: 50,
  search: "",
  fromdate: "",
  todate: "",
};

const initialState = {
  allExplicitContent: [],
  videoClipsQueryFilters: { ...clipsInitialFilters },
  redirect: "",
  data: [],
  dataLength: null,
  total: 1,
  totalPages: null,
  explicitStats: [],
};

const explicit = (state = initialState, action) => {
  switch (action.type) {
    case "GET_EXPLICIT_CONTENT_STAT_DATA": {
      return {
        ...state,
        explicitStats: action.data,
      };
    }
    case "SET_EXPLICIT_CONTENT": {
      return {
        ...state,
        videoClipsQueryFilters: {
          ...state.videoClipsQueryFilters,
          ...action.query,
        },

        data: action.data,
        dataLength: action.dataLength,
        totalPages: action.page,
      };
    }
    case "SET_MORE_EXPLICIT_CONTENT": {
      const { data } = action;

      const updatedList = [...state.data, ...data];
      return {
        ...state,
        videoClipsQueryFilters: {
          ...state.videoClipsQueryFilters,
          ...action.query,
        },

        data: updatedList,
        // dataLength: action.dataLength,
        // totalPages: action.page,
      };
    }
    case "SET_FILTER": {
      return {
        ...state,
        videoClipsQueryFilters: {
          ...state.videoClipsQueryFilters,
          ...action.query,
        },
      };
    }
    default:
      return { ...state };
  }
};
export default explicit;
