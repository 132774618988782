export const B2B_JOB_LIST_SET_QUERY = "B2B_JOB_LIST_SET_QUERY";

export const SET_B2B_JOB_USERS_DATA = "SET_B2B_JOB_USERS_DATA";
export const SET_B2B_JOB_STATS_DATA = "SET_B2B_JOB_STATS_DATA";
export const GET_B2B_JOB_USER_STATISTICS = "GET_B2B_JOB_USER_STATISTICS";

export const SET_B2B_JOB_USERS_LIST_LOADER_STATE = "SET_LOADER_STATE";

export const RESET_B2B_JOB_USER_LIST_FILTERS =
  "RESET_B2B_JOB_USER_LIST_FILTERS";
