export const B2B_CLIENT_LIST_SET_QUERY = "B2B_CLIENT_LIST_SET_QUERY";

export const SET_B2B_CLIENT_DATA = "SET_B2B_CLIENT_DATA";
export const SET_B2B_CLIENT_STATS_DATA = "SET_B2B_CLIENT_STATS_DATA";
export const GET_B2B_CLIENT_STATISTICS = "GET_B2B_CLIENT_STATISTICS";

export const SET_B2B_CLIENT_LIST_LOADER_STATE =
  "SET_B2B_CLIENT_LIST_LOADER_STATE";

export const RESET_B2B_CLIENT_LIST_FILTERS = "RESET_B2B_CLIENT_LIST_FILTERS";
