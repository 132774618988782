import {
  SET_CONTEST_FILTERS,
  SET_CONTEST_LIST,
  SET_CONTEST_LIST_LOADING_STATE,
  SET_CONTEST_STATISTICS,
  SET_MORE_CONTEST,
  TOGGLE_MODAL,
  GET_CURRENT_CONTEST,
} from "../action/contestActions";

export const defaultContestFilters = {
  category_id: { value: "", label: "Select Category" },
  status: { value: "", label: "Select Status" },
  page: 1,
  limit: 10,
  search: "",
  fromdate: "",
  todate: "",
};

// ** Initial State
const initialState = {
  pages: 0,
  totalContest: 0,
  listView: false,
  listLoadingState: false,
  contestStatistics: {
    totalcampaign: 0,
    totalActiveContest: 0,
    totalParticipet: 0,
    totalVideo: 0,
  },
  contestList: [],
  contestFilters: defaultContestFilters,
  isModelOpen: false,
  contestid: null,
  contestdata: {},
  winnersData: [],
  totalLength: null,
};

const contestReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CONTEST_LIST: {
      return {
        ...state,
        contestList: action.contestList,
        totalLength: action.totalDocuments,
        pages: action.pages,
        listLoadingState: false,
      };
    }

    case SET_CONTEST_STATISTICS: {
      return {
        ...state,
        contestStatistics: action.contestStatistics,
        listLoadingState: false,
      };
    }

    case SET_MORE_CONTEST: {
      const { contestList } = action;
      // const totalClips = action.totalClips;
      const pages = action.pages;
      const updatedList = [...state.contestList, ...contestList];

      // return { ...state, allClips: updatedClips, contestList, pages };
      return { ...state, contestList: updatedList };
    }

    case SET_CONTEST_FILTERS: {
      return {
        ...state,
        contestFilters: action.query,
      };
    }

    case SET_CONTEST_LIST_LOADING_STATE: {
      return {
        ...state,
        listLoadingState: action.loading,
      };
    }
    case TOGGLE_MODAL:
      return {
        ...state,
        isModelOpen: action.data,
        contestid: action.id,
      };
    case GET_CURRENT_CONTEST:
      return { ...state, contestdata: action.data, winnersData: action.data };
    case "WINNERS_DETAIL":
      return {
        ...state,
      };
    default:
      return { ...state };
  }
};

export default contestReducer;
