// ** Redux Imports
import { combineReducers } from "redux";

// ** Reducers Imports
import auth from "./auth";
import navbar from "./navbar";
import layout from "./layout";
import dashboard from "@src/views/Dashboard/store/reducer";
import roles from "@src/views/Roles/store/reducer";
import category from "@src/views/Category/store/reducer";
import wowusers from "@src/views/Users/WowUser/store/reducer";
import b2bArtist from "@src/views/B2bArtist/store/reducer";
import b2bContracts from "@src/views/B2bContracts/store/reducer";
import b2bClient from "@src/views/B2bClients/store/reducer";
import b2bJob from "@src/views/B2bJobList/store/reducer";
import internalusers from "@src/views/Users/InternalUser/store/reducer";
import pageInfo from "@src/views/Page-Info/store/reducer";
import faq from "@src/views/Faq/store/reducer";
import clips from "@src/views/Clips/store/reducer";
import reported from "@src/views/ReportedContent/store/reducer";
import explicit from "@src/views/ExplicitContent/store/reducer";
import kyc from "@src/views/KYC/store/reducer";
import coinWithdrawal from "@src/views/CoinWithdrawalRequest/store/reducer";
import coinPurchase from "@src/views/CoinPurchased/store/reducer";
import coinTransferHistory from "@src/views/CoinTransferHistory/store/reducer";
import coinEarningHistory from "@src/views/CoinEarningHistory/store/reducer";
import freeCoinEarning from "@src/views/FreeCoinEarning/store/reducer";
import notification from "@src/views/PushNotification/store/reducer";
import setting from "@src/views/Setting/store/reducer";
import contestReducer from "../../views/Contest/store/reducer";
import artistOfTheWeekReducer from "../../views/ArtistOfTheWeek/store/reducer";
import contestViewReducer from "../../views/ContestView/store/reducer";
import reportedComments from "@src/views/ReportedComments/store/reducer/index";
import reportedMessage from "@src/views/ReportedMessage/store/reducer";
import reportedAccounts from "@src/views/ReportedAccounts/store/reducer";
import feedbackMessage from "../../views/FeedbackMessages/store/reducer";
import celebLiveReducer from "../../views/CelebLive/store/reducer";
const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  contest: contestReducer,
  celebLiveReducer,
  artistOfTheWeek: artistOfTheWeekReducer,
  contestViewReducer,
  dashboard,
  roles,
  category,
  wowusers,
  b2bArtist,
  b2bContracts,
  b2bClient,
  b2bJob,
  internalusers,
  pageInfo,
  faq,
  clips,
  reported,
  notification,
  setting,
  explicit,
  kyc,
  coinWithdrawal,
  coinPurchase,
  coinTransferHistory,
  coinEarningHistory,
  freeCoinEarning,
  reportedComments,
  reportedMessage,
  reportedAccounts,
  feedbackMessage,
});

export default rootReducer;
