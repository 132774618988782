export const SET_CLIPS_FILTERS = "SET_CLIPS_FILTERS";

export const RESET_CLIPS_FILTERS = "RESET_CLIPS_FILTERS";

export const SET_CLIPS_LOADING_STATE = "SET_CLIPS_LOADING_STATE";

export const SET_CLIPS_STATISTICS = "SET_CLIPS_STATISTICS";

export const GET_ALL_CLIPS = "GET_ALL_CLIPS";

export const SET_MORE_CLIPS = "SET_MORE_CLIPS";


export const TOGGLE_LIST_VIEW = "TOGGLE_LIST_VIEW";
