import axios from "axios";
import { toast } from "react-toastify";
import { all, takeLatest, put, call } from "redux-saga/effects";
import {
  GET_CELEBLIVE_LIST,
  GET_CELEBLIVE_STATISTICS,
  GET_MORE_CELEBLIVE,
  SET_CELEBLIVE_LIST,
  SET_CELEBLIVE_STATISTICS,
  SET_MORE_CELEBLIVE,
} from "./action/celebLiveActions";
import { BASEURL, Token, timezone } from "@utils";
import { removeEmptyFieldsFromObject } from "../../../utility/Utils";
import { defaultCelebLiveFilters } from "./reducer";

const getCelebLiveList = async (query) => {
  try {
    const simplifiedQuery = {
      ...query,
    };
    simplifiedQuery.page = defaultCelebLiveFilters.page;
    const updatedQuery = removeEmptyFieldsFromObject(simplifiedQuery);

    const contestResponse = await axios.get(
      `${BASEURL}/livestreamings/prime_time_slot_list`,
      {
        params: {
          ...updatedQuery,
        },
        headers: { Token, timezone },
      }
    );

    if (!contestResponse?.data?.data?.data) {
      if (contestResponse?.data?.error) {
        toast.error(
          contestResponse?.data?.error.msg ??
            "Something went wrong while getting contest list!"
        );
        console.log(
          "Something went wrong while getting contest list!",
          contestResponse?.data?.error
        );
        return;
      }
      console.log(
        "Something went wrong while getting contest list!",
        contestResponse?.data?.error
      );
      toast.error("Something went wrong while getting contest list!");
      return [];
    }

    return {
      constestListData: contestResponse?.data?.data?.data,
      totalDocuments: contestResponse?.data?.data?.total_documents,
      pages: contestResponse?.data?.data?.pages,
    };
  } catch (err) {
    console.log("Something went wrong while getting contest list!", err);
    toast.error("Something went wrong while getting contest list!");
  }
};

export const fetchMoreCelebLive = async (query) => {
  //   console.log("query", query);
  try {
    const simplifiedQuery = {
      page: query.page,
    };
    const updatedQuery = removeEmptyFieldsFromObject(simplifiedQuery);
    const contestResponse = await axios.get(
      `${BASEURL}/livestreamings/prime_time_slot_list`,
      {
        params: {
          ...updatedQuery,
        },
        headers: { Token, timezone },
      }
    );

    if (!contestResponse?.data?.data?.data) {
      if (contestResponse?.data?.error) {
        toast.error(
          contestResponse?.data?.error.msg ??
            "Something went wrong while getting contest list!"
        );
        console.log(
          "Something went wrong while getting contest list!",
          contestResponse?.data?.error
        );
        return;
      }
      console.log(
        "Something went wrong while getting contest list!",
        contestResponse?.data?.error
      );
      toast.error("Something went wrong while getting contest list!");
      return [];
    }

    return {
      constestListData: contestResponse?.data?.data?.data,
      pages: contestResponse?.data?.data?.pages,
    };
  } catch (err) {
    console.log("Something went wrong while getting Wow users list!", err);
    toast.error("Something went wrong while getting Wow users list!");
  }
};

const getCelebLiveStatistics = async (query) => {
  // console.log("query", query);
  try {
    const simplifiedQuery = {
      ...query,
      search: query?.search,
    };

    delete simplifiedQuery.page;
    // delete simplifiedQuery.search;
    delete simplifiedQuery.limit;

    const updatedQuery = removeEmptyFieldsFromObject(simplifiedQuery);

    const contestStatisticsResponse = await axios.get(
      `${BASEURL}/livestreamings/streamscounts`,
      {
        params: {
          ...updatedQuery,
        },
        headers: { Token, timezone },
      }
    );

    if (!contestStatisticsResponse?.data?.data) {
      if (contestStatisticsResponse?.data?.error) {
        toast.error(
          contestStatisticsResponse?.data?.error.msg ??
            "Something went wrong while getting contest statistics!"
        );
        console.log(
          "Something went wrong while getting contest statistics!",
          err
        );
        return;
      }
      console.log("Something went wrong while getting contest list!", err);
      toast.error("Something went wrong while getting contest list!");
      return undefined;
    }

    return contestStatisticsResponse?.data?.data;
  } catch (err) {
    console.log("Something went wrong while getting contest statistics!", err);
    toast.error("Something went wrong while getting contest statistics!");
  }
};

function* getCelebLiveSaga(action) {
  try {
    const contestList = yield call(getCelebLiveList, action.query);

    yield put({
      type: SET_CELEBLIVE_LIST,
      slotList: contestList?.constestListData,
      totalDocuments: contestList.totalDocuments,
      pages: contestList.pages,
    });
  } catch (err) {
    console.log("Something went wrong while getting contest list!", err);
    toast.error("Something went wrong while getting contest list!");
  }
}

function* getCelebLiveStatisticsSaga(action) {
  try {
    const contestStatistics = yield call(getCelebLiveStatistics, action.query);

    yield put({
      type: SET_CELEBLIVE_STATISTICS,
      contestStatistics: contestStatistics,
    });
  } catch (err) {
    console.log("Something went wrong while getting contest statistics!", err);
    toast.error("Something went wrong while getting contest statistics!");
  }
}

function* getMoreCelebLiveSaga(action) {
  //   console.log("action.query", action.query);
  try {
    const contestList = yield call(fetchMoreCelebLive, action.query);

    yield put({
      type: SET_MORE_CELEBLIVE,
      contestList: contestList.constestListData,
    });
  } catch (err) {
    console.log("Something went wrong while getting contest list!", err);
    toast.error("Something went wrong while getting contest list!");
  }
}

export function* celebLiveSagas() {
  yield all([
    takeLatest(GET_CELEBLIVE_LIST, getCelebLiveSaga),
    takeLatest(GET_CELEBLIVE_STATISTICS, getCelebLiveStatisticsSaga),
    takeLatest(GET_MORE_CELEBLIVE, getMoreCelebLiveSaga),
  ]);
}
