import axios from "axios";
import { toast } from "react-toastify";
import { all, takeLatest, put, call } from "redux-saga/effects";
import {
  GET_CONTEST_LIST,
  GET_CONTEST_STATISTICS,
  GET_MORE_CONTEST,
  SET_CONTEST_LIST,
  SET_CONTEST_STATISTICS,
  SET_MORE_CONTEST,
} from "./action/contestActions";
import { BASEURL, Token, timezone } from "@utils";
import { removeEmptyFieldsFromObject } from "../../../utility/Utils";
import { defaultContestFilters } from "./reducer";

const getContestList = async (query) => {
  try {
    const simplifiedQuery = {
      ...query,
      category_id: query.category_id?.value,
      status: query.status?.value,
    };
    simplifiedQuery.page = defaultContestFilters.page;
    const updatedQuery = removeEmptyFieldsFromObject(simplifiedQuery);

    const contestResponse = await axios.get(
      `${BASEURL}/campaign/getcampaignslist`,
      {
        params: {
          ...updatedQuery,
        },
        headers: { Token, timezone },
      }
    );

    if (!contestResponse?.data?.data?.data) {
      if (contestResponse?.data?.error) {
        toast.error(
          contestResponse?.data?.error.msg ??
            "Something went wrong while getting contest list!"
        );
        console.log(
          "Something went wrong while getting contest list!",
          contestResponse?.data?.error
        );
        return;
      }
      console.log(
        "Something went wrong while getting contest list!",
        contestResponse?.data?.error
      );
      toast.error("Something went wrong while getting contest list!");
      return [];
    }

    return {
      constestListData: contestResponse?.data?.data?.data,
      totalDocuments: contestResponse?.data?.data?.total_documents,
      pages: contestResponse?.data?.data?.pages,
    };
  } catch (err) {
    console.log("Something went wrong while getting contest list!", err);
    toast.error("Something went wrong while getting contest list!");
  }
};

export const fetchMoreContest = async (query) => {
//   console.log("query", query);
  try {
    const simplifiedQuery = {
      ...query,
      category_id: query.category_id.value,
      status: query.status.value,
      page: query.page,
    };
    const updatedQuery = removeEmptyFieldsFromObject(simplifiedQuery);
    const contestResponse = await axios.get(
      `${BASEURL}/campaign/getcampaignslist`,
      {
        params: {
          ...updatedQuery,
        },
        headers: { Token, timezone },
      }
    );

    if (!contestResponse?.data?.data?.data) {
      if (contestResponse?.data?.error) {
        toast.error(
          contestResponse?.data?.error.msg ??
            "Something went wrong while getting contest list!"
        );
        console.log(
          "Something went wrong while getting contest list!",
          contestResponse?.data?.error
        );
        return;
      }
      console.log(
        "Something went wrong while getting contest list!",
        contestResponse?.data?.error
      );
      toast.error("Something went wrong while getting contest list!");
      return [];
    }

    return {
      constestListData: contestResponse?.data?.data?.data,
      pages: contestResponse?.data?.data?.pages,
    };
  } catch (err) {
    console.log("Something went wrong while getting Wow users list!", err);
    toast.error("Something went wrong while getting Wow users list!");
  }
};

const getContestStatistics = async (query) => {
  // console.log("query", query);
  try {
    const simplifiedQuery = {
      ...query,
      category_id: query.category_id?.value,
      status: query.status?.value,
      search: query?.search,
    };

    delete simplifiedQuery.page;
    // delete simplifiedQuery.search;
    delete simplifiedQuery.limit;

    const updatedQuery = removeEmptyFieldsFromObject(simplifiedQuery);

    const contestStatisticsResponse = await axios.get(
      `${BASEURL}/campaign/getcampaigncount`,
      {
        params: {
          ...updatedQuery,
        },
        headers: { Token, timezone },
      }
    );

    if (!contestStatisticsResponse?.data?.data) {
      if (contestStatisticsResponse?.data?.error) {
        toast.error(
          contestStatisticsResponse?.data?.error.msg ??
            "Something went wrong while getting contest statistics!"
        );
        console.log(
          "Something went wrong while getting contest statistics!",
          err
        );
        return;
      }
      console.log("Something went wrong while getting contest list!", err);
      toast.error("Something went wrong while getting contest list!");
      return undefined;
    }

    return contestStatisticsResponse?.data?.data;
  } catch (err) {
    console.log("Something went wrong while getting contest statistics!", err);
    toast.error("Something went wrong while getting contest statistics!");
  }
};

function* getContestsSaga(action) {
  try {
    const contestList = yield call(getContestList, action.query);

    yield put({
      type: SET_CONTEST_LIST,
      contestList: contestList.constestListData,
      totalDocuments: contestList.totalDocuments,
      pages: contestList.pages,
    });
  } catch (err) {
    console.log("Something went wrong while getting contest list!", err);
    toast.error("Something went wrong while getting contest list!");
  }
}

function* getContestStatisticsSaga(action) {
  try {
    const contestStatistics = yield call(getContestStatistics, action.query);

    yield put({
      type: SET_CONTEST_STATISTICS,
      contestStatistics: contestStatistics,
    });
  } catch (err) {
    console.log("Something went wrong while getting contest statistics!", err);
    toast.error("Something went wrong while getting contest statistics!");
  }
}

function* getMoreContestSaga(action) {
//   console.log("action.query", action.query);
  try {
    const contestList = yield call(fetchMoreContest, action.query);

    yield put({
      type: SET_MORE_CONTEST,
      contestList: contestList.constestListData,
    });
  } catch (err) {
    console.log("Something went wrong while getting contest list!", err);
    toast.error("Something went wrong while getting contest list!");
  }
}

export function* contestSagas() {
  yield all([
    takeLatest(GET_CONTEST_LIST, getContestsSaga),
    takeLatest(GET_CONTEST_STATISTICS, getContestStatisticsSaga),
    takeLatest(GET_MORE_CONTEST, getMoreContestSaga),
  ]);
}
