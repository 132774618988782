export const B2B_CONTRACT_LIST_SET_QUERY = "B2B_CONTRACT_LIST_SET_QUERY";

export const SET_B2B_CONTRACT_DATA = "SET_B2B_CONTRACT_DATA";
export const SET_B2B_CONTRACT_STATS_DATA = "SET_B2B_CONTRACT_STATS_DATA";
export const GET_B2B_CONTRACT_STATISTICS = "GET_B2B_CONTRACT_STATISTICS";

export const SET_B2B_CONTRACT_LIST_LOADER_STATE =
  "SET_B2B_CONTRACT_LIST_LOADER_STATE";

export const RESET_B2B_CONTRACT_LIST_FILTERS =
  "RESET_B2B_CONTRACT_LIST_FILTERS";
