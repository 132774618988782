export const initialListQuery = {
  page: 1,
  limit: 50,
  message_type: "",
  search: "",
  fromdate: "",
  todate: "",
};

const initialState = {
  statdata: null,
  listdata: [],
  datapages: null,
  total_documents: null,
  data: [],
  userListLoader: true,
  usersListQuery: { ...initialListQuery },
  pages: null,
  downloadData: [],
};

const feedbackMessage = (state = initialState, action) => {
  switch (action.type) {
    case "GET_FEEDBACK_MESSAGE_STAT_DATA":
      return {
        ...state,
        statdata: action.data,
      };
    case "GET_FEEDBACK_MESSAGE_LIST_DATA":
      return {
        ...state,
        listdata: action.data,
        total_documents: action.total_documents,
        pages: action.pages,
      };
    case "GET_FEEDBACK_MESSAGE_DOWNLOAD_DATA":
      return {
        ...state,
        downloadData: action.data,
        // total_documents: action.total_documents,
        // pages:action.pages
      };
    case "FEEDBACK_MESSAGE_LIST_LOADER":
      return { ...state, userListLoader: action.loading };
    case "SET_FEEDBACK_MESSAGE_LIST_QUERY":
      return {
        ...state,
        usersListQuery: {
          ...state.usersListQuery,
          ...action.query,
        },
      };
    default:
      return state;
  }
};

export default feedbackMessage;
