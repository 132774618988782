export const videoInitiallyQuery = {
  page: 1,
  limit: 20,
  search: "",
  fromdate: "",
  todate: "",
  category_id: "",
};
// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  allfollowerlist: [],
  followerlist: [],
  totalfollower: 0,
  allfollowinglist: [],
  followinglist: [],
  totalfollowing: 0,
  selectedUser: {},
  notificationList: [],
  totalnotification: 0,
  Clips: [],
  pages: 0,
  totalClips: 0,
  videoScroll: [],
  videoScrollPage: 0,
  videoScrollDocument: 0,
  videoScrollLoadingState: true,
  videoQuery: { ...videoInitiallyQuery },
};

const contestViewReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_DATA":
      return { ...state, allData: action.data };

    case "GET_DATA":
      return {
        ...state,
        data: action.data,
        total: action.total,
      };

    case "GET_USER":
      return { ...state, selectedUser: action.selectedUser };

    case "GET_ALL_FOLLOWER_DATA":
      return { ...state, allfollowerlist: action.data };

    case "GET_FOLLOWER_LIST":
      return {
        ...state,
        followerlist: action.data,
        totalfollower: action.total,
      };

    case "GET_ALL_FOLLOWING_DATA":
     
      return { ...state, allfollowinglist: action.data };

    case "GET_FOLLOWING_LIST":
      return {
        ...state,
        followinglist: action.data,
        totalfollowing: action.total,
      };

    case "GET_NOTIFICATION_LIST":
      return {
        ...state,
        notificationList: action.data,
        totalnotification: action.total,
      };

    case "GET_CLIPS":
      return {
        ...state,
        Clips: [...state.allClips, ...action.data],
        totalClips: action.totalClips,
        pages: action.pages,
      };

    case "SET_MORE_VIDEO_LIST_IN_VIDEO_CLIPS": {
      const updatedVideoScroll = [...state.videoScroll, ...action.data];
      return {
        ...state,
        videoScroll: updatedVideoScroll,
        videoScrollDocument: action.total_documents,
        videoScrollPage: action.pages,
      };
    }
    case "GET_VIDEO_LIST_IN_VIDEO_CLIPS": {
      // console.log("redux", action.data);
      return {
        ...state,
        videoScroll: action.data,
        videoScrollDocument: action.total_documents,
        videoScrollPage: action.pages,
      };
    }
    case "SET_CLIPS_FILTERS": {
      return {
        ...state,
        videoQuery: action.query,
      };
    }

    case "SET_CLIPS_LOADING_STATE": {
      return {
        ...state,
        videoScrollLoadingState: action.loading,
      };
    }
    default:
      return { ...state };
  }
};
export default contestViewReducer;
